import { useAppSelector } from "lib/store/hooks";
import Button from "react-components/dist/components/Button";

import APIBpjs from "lib/constants/api/bpjs";

import { useCallback, useEffect, useRef, useState } from "react";
import useSettings from "pages/Settings/useSettings";
import modal from "react-components/dist/components/Modal";
import ModalConfirm from "components/molecules/ModalConfirm";

const SECONDS_PCARE = 180;

function Pcare() {
  const clinics = useAppSelector((state) => state.clinics);
  const refIntervalHealthCheck = useRef<
    ReturnType<typeof setInterval> | number
  >(0);

  const { onSubmit } = useSettings();

  const [isLoading, isLoadingSet] = useState(false);
  const [isError, isErrorSet] = useState(false);

  const shortPool = useCallback(() => {
    if (
      process.env.REACT_APP_STAGING &&
      ["staging", "production"].includes(process.env.REACT_APP_STAGING)
    ) {
      clearInterval(refIntervalHealthCheck.current);
      refIntervalHealthCheck.current = setInterval(async () => {
        try {
          isLoadingSet(true);
          await APIBpjs.getKesadaran();
          isLoadingSet(false);
          isErrorSet(false);
          // console.log(getHealthCheck);
        } catch (error) {
          isLoadingSet(false);
          isErrorSet(true);
          console.log(error);
        }
      }, 1000 * SECONDS_PCARE);
    }
  }, []);

  const fnChangeStatus = useCallback(() => {
    modal.show(
      <ModalConfirm
        isHighalert
        title="Perhatian"
        description="Yakin merubah status bridging P-Care?"
        onNext={{
          exec: async () => {
            const payload = {
              ...(clinics.selected || {}),
              metaData: {
                ...(clinics.selected?.metaData || {}),
                bpjs: {
                  ...(clinics.selected?.metaData?.bpjs || {}),
                  isConnected: !clinics.selected?.metaData?.bpjs?.isConnected,
                },
              },
            };

            await onSubmit(payload, "Berhasil memperbarui informasi P-Care");
          },
          label: !clinics.selected?.metaData?.bpjs?.isConnected
            ? "Aktifkan P-Care"
            : "Nonaktifkan P-Care",
          message: "Berhasil merubah status bridging P-Care",
        }}
      />,
      {
        close: {
          outsideClick: true,
          closeOnEscape: true,
          display: false,
        },
        wrapper: {
          overrideClassName: "w-[350px] text-center",
        },
      }
    );
  }, [clinics.selected, onSubmit]);

  useEffect(() => {
    shortPool();
    return () => {
      clearInterval(refIntervalHealthCheck.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="group relative mt-1 flex justify-center">
      <Button
        isLoading={isLoading}
        ripple
        className={[
          "w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900 relative",
          !isError ? "" : "grayscale",
        ].join(" ")}
        state="custom"
      >
        <button onClick={() => fnChangeStatus()}>
          {/* <button onClick={getKioskPage} onContextMenu={onContextMenuKiosk}> */}
          <img
            className="object-contain w-full h-full p-[6px]"
            src="/assets/images/logo-bpjs-kesehatan.webp"
            alt="logo bpjs"
          />
        </button>
      </Button>
      <span
        className={[
          "pointer-events-none border-2 -bottom-3 absolute flex h-4 items-center justify-center rounded-full bg-white dark:bg-gray-950 text-gray-900 dark:text-gray-200 text-[8px] font-black px-1",
          !isError ? "border-green-500" : "border-transparent",
        ].join(" ")}
      >
        PCARE
      </span>
      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
        {!clinics.selected?.metaData?.bpjs?.isConnected ? "Nonaktif" : "Aktif"}
      </span>
    </div>
  );
}

function Bpjs() {
  const { selected } = useAppSelector((state) => state.clinics);

  if (
    !selected?.metaData?.bpjs?.hasFinishedOnboarding &&
    !selected?.metaData?.vclaim?.hasFinishedOnboarding
  )
    return null;

  return <>{selected?.metaData?.bpjs?.hasFinishedOnboarding && <Pcare />}</>;
}

export default Bpjs;
