import { createSlice } from "@reduxjs/toolkit";

import { TUsersEntity } from "lib/constants/api/users";
type User = {
  entity: TUsersEntity;
  fullName: string;
  [key: string]: any;
};

export interface IUserState {
  token: string | null;
  tokenRefresh: string | null;
  profile: User | null;
  isAdminMejadokter?: string;
}

const initialState: IUserState = {
  token: null,
  tokenRefresh: null,
  profile: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.tokenRefresh = action.payload.tokenRefresh;

      if (action.payload.isAdminMejadokter)
        state.isAdminMejadokter = action.payload.isAdminMejadokter;
      if (action.payload.profile) state.profile = action.payload.profile;
    },
    refresh: (state, action) => {
      state.token = action.payload.token;
      state.tokenRefresh = action.payload.tokenRefresh;
    },
    logout: (state) => {
      state.token = null;
      state.tokenRefresh = null;
      state.profile = null;
      state.isAdminMejadokter = undefined;
    },
  },
});

export const { login, logout, refresh } = usersSlice.actions;

export default usersSlice.reducer;
